import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Enquiry from '../components/enquiry'

const ContactPage = props => {
  // const siteTitle = data.site.siteMetadata.title
  
  let {
    location,
    data: {
      wpPage: {
        excerpt,
        featuredImage: {
          node: {
            altText,
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
        acf_enquiry: {
          intro,
          enquiryForm,
        },
        seo,
        contentTypeName,
        slug,
      },
    },
  } = props

  // console.log(projects)

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />

      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-6 copy">{parse(intro)}</div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">{parse(excerpt)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  {parse(enquiryForm)}
                  <div className="row">
                    <Enquiry />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

export const pageQueryHome = graphql`
  {
    wpPage(slug: { eq: "contact-us" }) {
      title
      excerpt
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_enquiry {
        enquiryForm
        intro
      }
      seo {
        ...WpYoastSEO
      }
      slug
      contentTypeName
    }
  }
`
