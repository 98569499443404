import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Axios from "axios"

export default class Enquiry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      error: false,
      message: "",
    }
  }

  render() {
    const validationSchema = Yup.object().shape({
      yourName: Yup.string()
        .min(3, "Must be at least ")
        .max("50", "Too long to be a name!")
        .required("Must enter a name"),
      yourEmail: Yup.string()
        .email("Must be a valid email address")
        .max("50", "Too long to be an email!")
        .required("Must enter an email address"),
    })

    return (
      <Formik
        initialValues={{
          yourName: "",
          yourEmail: "",
          yourMessage: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (this.state.success === true) {
            // console.log("prevented");
            return
          }
          setSubmitting(true)
          // console.log(values);
          // console.log("submitting");
          let bodyFormData = new FormData()
          bodyFormData.set("yourName", values.yourName)
          bodyFormData.set("yourEmail", values.yourEmail)
          bodyFormData.set("yourMessage", values.yourMessage)

          Axios.post(
            `${process.env.WORDPRESS_FORMS}/wp-json/contact-form-7/v1/contact-forms/5/feedback`,
            bodyFormData
          )
            .then(res => {
              this.setState({
                message: res.data.message,
              })
              if (res.data.status === "mail_sent") {
                this.setState({
                  success: true,
                })
                resetForm()
              } else {
                this.setState({
                  error: true,
                })
              }
            })
            .catch(err => {
              this.setState({
                success: false,
                error: true,
                message: "Some error has occured, try again.",
              })
            })

          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="contactus" onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}

            <div className="row">
              <div className="col-md-6 mt-5">
                <input
                  className={`form-control text ${
                    touched.yourName && errors.yourName ? "is-invalid" : null
                  }`}
                  type="text"
                  name="yourName"
                  id="name"
                  placeholder="Name"
                  onChange={handleChange}
                  value={values.yourName}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-md-6 mt-5">
                <input
                  className={`form-control text ${
                    touched.yourEmail && errors.yourEmail ? "is-invalid" : null
                  }`}
                  type="email"
                  name="yourEmail"
                  id="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.yourEmail}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-md-12 mt-5">
                <textarea
                  className={`form-control message ${
                    touched.yourMessage && errors.yourMessage
                      ? "is-invalid"
                      : null
                  }`}
                  cols="30"
                  rows="5"
                  name="yourMessage"
                  id="message"
                  placeholder="Message"
                  onChange={handleChange}
                  value={values.yourMessage}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-md-12 mt-5">
                <button
                  type="submit"
                  aria-label="Submit the form"
                  className="btn btn-block link arrow"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
              <div className="col-md-12 mt-5">
                {this.state.message.length > 0 && (
                  <div className="text-center">
                    <span
                      className={`alert d-block mt-3 alert-${
                        this.state.success ? "success" : "danger"
                      }`}
                    >
                      {this.state.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}
